header.fancy {
    position: fixed;
    top: 0;
    transition: top 0.3s ease-in-out;
  }
  
  header.fancy.sticky {
    position: fixed;
    top: 0;
  }
  
  header.fancy.hidden {
    position:fixed;
    top: -96px;     /*Adjust this value based on your header height */
  }

main.fancy {
    margin-top: var(--headerHeight);
}

#header-content {
    flex: 1;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 24px var(--pagePadding) 24px var(--pagePadding);
}

.back-button-container {
    display: flex;
    align-items: center;
}

#btn-menu, 
#btn-back,
#btn-close {
    display: block;
    width: 20px;
    height: 20px;
    background-size: cover;
    cursor: pointer;
}

#btn-back {
    background-image: url('../svg/icon-arrow.svg');
}
#btn-menu {
    background-image: url('../svg/icon-menu.svg');
}
#btn-close {
    background-image: url('../svg/icon-close.svg');
    display: none;
}

#btn-menu:hover,
#btn-back:hover,
#btn-close:hover {
    opacity: 0.5;
}

header h2 {
    line-height: 20px;
}

header a {
    text-decoration: none;
}

@media (max-width: 767px) {

    /* Remove hover states for mobile */
    #btn-menu:hover{
        opacity: 1; /* Reset opacity to default */
    }
}