
.pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 72px; /* serves as bottom padding for page */
}

.nav-block{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.nav-block.slide{
    position: relative;
    width: 40%;
    background-color: rgba(var(--richBlack-rgb, 0, 0, 0), 0.05);
    height: 6px;
    border-radius: 10px;
    overflow: hidden;
}

.nav-block.current{
    cursor: default;
}
.nav-block.slide{
    cursor: pointer;
}
.nav-block.current .nav-dot{
    opacity: 1;
    transition: opacity 0.3s ease;
}

.nav-dot{
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--richBlack);
    opacity: 0.25;
    transition: opacity 0.3s ease-out;
}
.nav-block.slide .nav-dot{
    position: absolute;
    left:0;
    transition: transform 0.3s ease; /* Smooth animation */
}
.nav-block.collapsed .nav-dot{
    width: 2px;
    height: 2px;
    opacity: 0.3;
}

.nav-block:hover .nav-dot{
    opacity: 0.5;
}
.nav-block.current:hover .nav-dot{
    opacity: 1;
}

@media (max-width: 767px) {

    .nav-block{
        width: 15px;
    }

    .pagination{
        height: 72px; /* serves as bottom padding for page */
    }

    /* Remove hover states for mobile */
    .nav-block:hover .nav-dot{
        opacity: 1;
    }
}