
.thumb {
    aspect-ratio: 1 / 1;
    min-width: 350px;
    max-width: 600px;

    width:100%; 
    height: auto; 

    display: flex; 
    flex-direction: column; 
    justify-content: space-between;

    text-decoration: none;
    cursor: pointer; 

    background-color: #fff;
}

.thumb-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 24px 12px 24px;
}

.thumb-title h2 {
    color: var(--richBlack);
}

.thumb:hover h2 {
    opacity: 0.5;
}

.thumb:hover img {
    transform: scale(1.05); /* Increase size by 5% */
}

.thumb-image{
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumb img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    transition: transform 0.1s ease; /* Smooth transition */
}

.thumb img.size-18x24,
.thumb img.size-16x20 {
    width: 70%;
    height: 70%;
}
.thumb img.size-8_5x11,
.thumb img.size-11x8_5 {
    width: 55%;
    height: 55%;
}
.thumb img.size-8x8 {
    width: 50%;
    height: 50%;
}
.thumb img.size-4x6,
.thumb img.size-6x4 {
    width: 50%;
    height: 50%;
}



@media (max-width: 767px) {

    .thumb {
        width:80%;
        min-width: 220px;
        scroll-snap-align: start; /* scrolled by .works */
    }

    .thumb-title {
        padding: 0 18px 12px 18px;
    }
    
    /* Remove hover states for mobile */
    .thumb:hover h2,
    .thumb:hover hr,
    .thumb:hover img {
        opacity: 1; /* Reset opacity to default */
    }

    .thumb:hover img {
        transform: scale(1); /* cancel out desktop size increase */
    }
}