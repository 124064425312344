.page{
    display:flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    height: 100%;
    padding: 6px 0 12px 0;
    gap:12px;
}

.page-copy, .page-image {
    flex: 1; /* Take up equal portions of the screen width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    background-color: white;
}

.page-copy{
    display:flex;
    flex-direction: row;
    align-items: center;
    padding-left: 72px;
}
.page-image{
    padding: 24px;
}

.page-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.page h1 {
    margin-bottom: 18px;
}

.page p {
    max-width: 375px;
    line-height: 22px;
}

.page a {
    color: var(--textGray);
    /* text-decoration: underline; */
    text-decoration: none;
    position: relative;
    transition: all 0.3s ease; 
}
.page a::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px; 
    width: 100%;
    height: 1px; /* line thickness */
    
    background-color: var(--textGray); /* line color */
    transition: all 0.3s ease; 
}
.page a:hover::after {
    background-color: var(--richBlack); /* line color */
}

.page a:hover {
    color: var(--richBlack);
}

.page-copyBlock{
    position: relative;
}

.signature-svg {
    width: 110px;
    height: 80px;
    background-image: url('../svg/Signature.svg'); 
    background-size: contain; 
    background-repeat: no-repeat; 
    background-position: center; 
    position: absolute;
    right:0;
}

@media (max-width: 767px) {
    .page {
        flex-direction: column;
    }
    .page-image{
        flex: auto;
        height: 250px;
        max-height: 250px;
        padding: 24px;
        border-left: none;
    }
    .page-copy{
        flex: auto;
        padding:36px 24px 112px 24px;
        align-items: flex-start;
    }
    /* remove hover states for tap */
    .page a:hover {
        color: var(--textGray);
    }
    .page a:hover::after {
        background-color: var(--textGray); /* line color */
    }
}

