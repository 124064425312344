.footer-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 48px var(--pagePadding) 48px var(--pagePadding);
    background-color: #fff;
    box-sizing: border-box;
}
.footer-content.slim{
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: flex-end;
}

.footer-content h3 {
    width: 260px;
}

@media (max-width: 767px) {

}