.nav-content {
    padding: 130px var(--pagePadding) 130px var(--pagePadding); 
}

nav ul li {
    margin-bottom: 48px;
}

nav ul li:last-child {
    margin-bottom: 0;
}

nav ul li h1:hover{
    opacity: 0.5;
}

@media (max-width: 767px) {
    .nav-content {
        padding: 80px var(--pagePadding) 80px var(--pagePadding);
      }

      nav ul li h1:hover{
        opacity: 1;
    }
}