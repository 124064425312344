@import url('reset.css');
@import url('fonts.css');


/* / / / / / / / / / / Variables */

:root {
    --pagePadding: 72px;
    --navWidth: 300px;

    --fontSize: 15px;
    --lineHeight: 20px;

    --richBlack: #1E1E1E;
    --softGray: #F7F7F7;
    --textGray: #B6B6B6;
}

/* Small devices (mobile) */
@media (max-width: 767px) {
    :root {
        --pagePadding: 24px;
        --navWidth: 70%;
    }
}

/* / / / / / / / / / / Typography */

body, p, h1, h2, a {
  font-family: 'RecoletaRegular', sans-serif;
  color: var(--richBlack);
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

h2 {
    color: var(--textGray);
}

h3, h4 {
    font-size: 12px;
    line-height: 16px;
    color: var(--richBlack);
}

h4 {
    color: var(--textGray);
}

hr{
    border: 0;
    margin: 0;
    border-top: 1px solid var(--richBlack);
}

@media (max-width: 767px) {

}

/* / / / / / / / / / / Borders */

.border-top {
    border-top: 1px solid var(--richBlack);
}
.border-bottom {
    border-bottom: 1px solid var(--richBlack);
}
.border-left {
    border-left: 1px solid var(--richBlack);
}
.border-right {
    border-right: 1px solid var(--richBlack);
}


/* / / / / / / / / / / Structure */

.App {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: var(--softGray);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
}

header {
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    box-sizing: border-box;
    height: 90px;
    width: 100%;
    z-index: 100;
    background-color: white;
    border-bottom: 6px solid var(--softGray);
    position: fixed;
    top: 0;
}

main {
    flex-grow: 1;
    height:100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: var(--softGray);
    margin-top: 90px;
}
main.collage {
    margin-top: 0px;
}

footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}


/* / / / / / / / / / / Utils */

.hide {
    display: none;
}

.small-text {
    font-family: 'RecoletaRegular', sans-serif;
    font-size: 16px;
    line-height: 20px;
}
.bold-text {
    font-family: 'RecoletaBold', sans-serif;
}

.text-gray {
    color: var(--textGray);
}

.transparent-bg {
    background-color: transparent;
}

.loading {
    background-image: url('../../public/images/loader.gif'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 80px;
}
.pre.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}