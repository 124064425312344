.series{
    display:flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow:1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    scroll-snap-align: start; /* scrolled by .series-container-scroller */
}

.series-title{
    width: 100%;
    padding: 24px var(--pagePadding) 24px var(--pagePadding);
    box-sizing: border-box;
}

.artwork-container-scroller{
    display:flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow:1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}
/* Hide scrollbar for WebKit browsers */
.artwork-container-scroller::-webkit-scrollbar {
    display: none;
}

.artwork-container{
    display: flex;
    flex-direction: row; /* this caused the issue - removing height from child .artwork solves */
    align-items: stretch;
    flex-grow: 1;
    position: relative;
    /* width: 100%;  */
    height: 100%;
    box-sizing: border-box;
}


@media (max-width: 767px) {

}