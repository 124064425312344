
.artwork{
    position: relative;
    display: flex;
    flex-grow:1;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    /* height: 100%;  */
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 6px;
    scroll-snap-align: start; /* scrolled by .artwork-container-scroller */
}

.loading.image-loader{
    /* display: flex; */
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: absolute; 
    top: 0;             
    left: 0;    
}

.image-container{
    display: flex;
    flex-grow:1;
    flex-direction: column;
    align-items: stretch;
    /* height: 100%; */
    padding: 48px 72px 24px 72px;
    box-sizing: border-box;
}

.full-image{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
    background-size: contain;
    background-position: center; 
    background-repeat: no-repeat;
    box-sizing: border-box;
}

.artwork-info{
    padding-left: 72px;
    padding-right: 72px;
    padding-bottom: 24px;
    width:100%;
    /* height: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    bottom:0;
}
.artwork-info > :first-child {
    position: absolute;
    left: 72px; /* Align with left padding */
}

.artwork-info > :last-child {
    position: absolute;
    right: 72px; /* Align with right padding */
}

.artwork-info > :nth-child(2) {
    margin: auto; /* Center the middle element */
}

.btn-size {
    display: block;
    width: 40px;
    height: 16px;
    background-size: 22px 16px; /* Set exact pixel size for background */
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../svg/icon-eye.svg');
    cursor: pointer;
    opacity: 0.25;
    transition: all 0.3s ease; 
}
.btn-size:hover {
    opacity: 0.5;
}

@media (max-width: 767px) {

    .artwork{
        margin-top: 0;
    }

    .image-container{
        padding: 24px 24px 24px 24px;
    }

    .artwork-info{
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 18px;
    }
    .artwork-info > :first-child {
        left: 24px; /* Align with left padding */
    }
    
    .artwork-info > :last-child {
        right: 24px; /* Align with right padding */
    }

    .btn-size:hover {
        opacity: 0.25;
    }
}
