.collections{
    padding: 200px 48px 0 48px;
}
.collection-title{
    padding: 0 0 12px 24px;
}

.works {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap:6px;
    padding-bottom: 200px;

  }

  @media (max-width: 767px) {
    .collections{
        padding: 100px 0 0 0;
    }

    .collection-title{
        padding: 0 0 12px 24px;
    }

    .works {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        gap: 6px;
        padding-left: 12px;
        padding-bottom: 100px; /* Adjust padding as needed */
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scroll-padding-left: 12px;
    }

    .works > * {
        flex: 0 0 auto; /* Ensure items don't shrink and maintain their width */
    }

}