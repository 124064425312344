.series-container-scroller{
    display:flex;
    align-items: flex-start;
    flex-direction: row;
    flex-grow:1;
    width: 100%;
    height: 100vh;
    /* height: var(--vh, 100vh); */
    box-sizing: border-box;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.series-container{
    display: flex;
    flex-direction: column; 
    align-items: stretch;
    flex-grow: 1;
    position: relative;
    width: 100%; 
    height: 100%;
    box-sizing: border-box;
}

#btn-close-viewer {
    display: block;
    width: 20px;
    height: 20px;
    background-size: cover;
    cursor: pointer;
    z-index: 1000;

    position:absolute;
    top: 32px;
    right: var(--pagePadding);
}
#btn-close-viewer {
    background-image: url('../svg/icon-close.svg');
}
#btn-close-viewer:hover {
    opacity: 0.5;
}

@media (max-width: 767px) {
    .series-container{
        margin-bottom: 500px; /* hack to componsate for nav bar vh calulation on mobile */
    }
}
